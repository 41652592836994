<template>
  <section>
    <validation-observer ref="aircraftForm">
      <b-card>
        <b-row>
          <b-col cols="12">
            <app-select
              id="aircraftSelect"
              v-model="selectedAircraft"
              :options="aircraftsOptions"
              :label-field="$t('aircraft.select')"
              label="name"
              v-bind="aircraftsMetaData"
              :clearable="false"
              paginated
              :searching="isLoading"
              :overlay="isLoading"
              @option:selected="changeSelectedAircraft($event)"
              @click:previous-page="fetchAircrafts(aircraftsMetaData.previousPage)"
              @click:next-page="fetchAircrafts(aircraftsMetaData.nextPage)"
              @search="
                search => {
                  fetchAircrafts(1, search)
                }
              "
            />
          </b-col>
          <b-col v-if="showInputName" cols="12">
            <app-input id="aircraftName" v-model="organizationAircraftData.name" :label="$t('aircraft.name')" required />
          </b-col>
        </b-row>
      </b-card>

      <section v-if="selectedAircraft || showAircraftData">
        <b-form @submit.prevent="onSubmit">
          <b-row class="match-height">
            <b-col cols="12" md="6">
              <b-card>
                <b-row>
                  <b-col cols="12" md="6">
                    <div class="d-flex">
                      <h4 class="mb-1">
                        {{ $t('aircraft.selected_aircraft') }}
                        {{ selectedAircraft && selectedAircraft.id ? selectedAircraft.name : organizationAircraftData.name }}
                      </h4>
                    </div>
                  </b-col>

                  <!-- ACTIVE -->
                  <b-col cols="12" md="6">
                    <validation-provider #default="{ errors }" :name="$t('common.active')">
                      <b-form-group
                        class="d-flex justify-content-end"
                        :label="$t('common.active')"
                        label-for="aircraftActive"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-checkbox id="aircraftActive" v-model="organizationAircraftData.active" class="pl-1" name="check-button" switch inline />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Field: Aircraft type -->
                  <b-col cols="12" md="6">
                    <app-input id="aircraftType" v-model="organizationAircraftData.type" :label="$t('aircraft.type')" required />
                  </b-col>

                  <!-- Field: Aircraft model -->
                  <b-col cols="12" md="6">
                    <app-input id="aircraftModel" v-model="organizationAircraftData.model" :label="$t('aircraft.model')" required />
                  </b-col>

                  <!-- Field: Manufacturer -->
                  <b-col cols="12">
                    <app-input id="aircraftManufacturer" v-model="organizationAircraftData.manufacturer" :label="$t('aircraft.manufacturer')" required />
                  </b-col>

                  <!-- Field: registration -->
                  <b-col cols="12" md="6">
                    <app-input id="aircraftRegistration" v-model="organizationAircraftData.registration" :label="$t('aircraft.registration')" />
                  </b-col>

                  <!-- Field: call sign -->
                  <b-col cols="12" md="6">
                    <app-input id="aircraftCallSign" v-model="organizationAircraftData.callSign" :label="$t('aircraft.call_sign')" required />
                  </b-col>

                  <!-- Field: Homebase -->
                  <b-col cols="12" md="6">
                    <enum-select
                      id="aircraftHomebase"
                      v-model="organizationAircraftData.homeBaseCode"
                      :label="$t('aircraft.homebase')"
                      text="text"
                      enum-class="CountryCode"
                      :overlay="isLoading"
                      required
                    />
                  </b-col>

                  <!-- AOC -->
                  <b-col cols="12" md="6">
                    <app-input id="aircrafAoc" v-model="organizationAircraftData.aoc" :label="$t('aircraft.aoc')" />
                  </b-col>

                  <!-- Notice -->
                  <b-col cols="12">
                    <p class="mt-1 text-danger">{{ $t('common.notice') }} {{ $t('navigation.organization_aircrafts_note') }}</p>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12" md="6">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex">
                      <h4 class="mb-1">{{ $t('aircraft.information_about') }}</h4>
                    </div>
                  </b-col>

                  <!-- Field: MTOW -->
                  <b-col cols="12">
                    <app-input
                      id="aircraftMtow"
                      v-model.number="organizationAircraftData.mtow"
                      :label="$t('aircraft.mtow')"
                      :append="$getMeasurementUnitItn('kg')"
                      step="any"
                      type="number"
                      rules="required|positive"
                    />
                  </b-col>

                  <!-- Field: Number of seats -->
                  <b-col cols="12">
                    <app-input
                      id="aircraftNumberSeat"
                      v-model.number="organizationAircraftData.numberOfSeats"
                      :label="$t('aircraft.number_seat')"
                      rules="required|positive"
                      type="number"
                    />
                  </b-col>

                  <b-col cols="12">
                    <enum-select
                      v-model="organizationAircraftData.flightRules"
                      :label="$t('aircraft.rules')"
                      input-id="flightRules"
                      enum-class="FlightRule"
                      :reduce="option => option.value"
                      :overlay="isLoading"
                      multiple
                    />
                  </b-col>

                  <b-col cols="12" md="6">
                    <app-select
                      id="aircraftFireFighting"
                      v-model="organizationAircraftData.fireFighting"
                      :label-field="$t('aircraft.fire_fighting_category')"
                      :options="fireFightingOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      required
                    />
                  </b-col>

                  <!-- Field: Aircraft category -->
                  <b-col cols="12" md="6">
                    <app-input id="aircraftCategory" v-model="organizationAircraftData.category" :label="$t('aircraft.category')" required />
                  </b-col>

                  <!-- Field: Min flight crew -->
                  <b-col cols="12" md="6">
                    <app-input
                      id="minFlightCrew"
                      v-model.number="organizationAircraftData.minFlightCrew"
                      :label="$t('aircraft.min_flight_crew')"
                      rules="positive|integer"
                      type="number"
                    />
                  </b-col>

                  <!-- Field: Min runway length -->
                  <b-col cols="12" md="6">
                    <app-input
                      id="minRunwayLength"
                      v-model.number="organizationAircraftData.minRunwayLength"
                      :label="$t('aircraft.min_runway_length')"
                      :append="$getMeasurementUnitItn('m')"
                      rules="positive"
                      step="any"
                      type="number"
                    />
                  </b-col>

                  <!-- Field: height -->
                  <b-col cols="12" md="6">
                    <app-input
                      id="aircraftHeight"
                      v-model.number="organizationAircraftData.height"
                      :label="$t('aircraft.height')"
                      :append="$getMeasurementUnitItn('m')"
                      step="any"
                      type="number"
                      rules="required|positive|excluded:0"
                    />
                  </b-col>

                  <!-- Field: length -->
                  <b-col cols="12" md="6">
                    <app-input
                      id="aircraftLength"
                      v-model.number="organizationAircraftData.length"
                      :label="$t('aircraft.length')"
                      :append="$getMeasurementUnitItn('m')"
                      step="any"
                      type="number"
                      rules="required|positive"
                    />
                  </b-col>

                  <!-- Field: Wingspan -->
                  <b-col cols="12">
                    <app-input
                      id="aircraftWignspan"
                      v-model.number="organizationAircraftData.wingspan"
                      :label="$t('aircraft.wingspan')"
                      :append="$getMeasurementUnitItn('m')"
                      step="any"
                      type="number"
                      rules="required|positive|excluded:0"
                    />
                  </b-col>

                  <!-- FUEL TYPES -->
                  <b-col cols="12">
                    <enum-select
                      id="fuelTypes"
                      v-model="organizationAircraftData.fuelTypesIds"
                      :label="$t('aircraft.fuel_types')"
                      enum-class="FuelType"
                      :reduce="option => option.value"
                      :overlay="isLoading"
                      multiple
                      required
                    />
                  </b-col>

                  <!-- Field: Maximum fuel capacity -->
                  <b-col cols="12">
                    <app-input
                      id="maxFuelCapacity"
                      v-model.number="organizationAircraftData.maxFuelCapacity"
                      :label="$t('aircraft.max_fuel')"
                      :append="$getMeasurementUnitItn('l')"
                      rules="required|positive"
                      step="any"
                      type="number"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" class="d-flex justify-content-end mb-3">
              <b-button variant="outline-secondary" :block="mdAndDown" :size="mdAndDown ? 'sm' : 'md'" @click.prevent="modalCancel">
                <font-awesome-icon icon="ban" class="mr-50" />
                <span>{{ $t('action.cancel') }}</span>
              </b-button>

              <div id="saveAndAdd" v-b-tooltip.hover="organizationAircraftData.boundToTrip ? $t('aircraft.unable_edit_bound_to_trip') : ''">
                <b-button class="ml-1 mt-0" type="submit" :block="mdAndDown" :size="mdAndDown ? 'sm' : 'md'" :disabled="isSubmitting || organizationAircraftData.boundToTrip">
                  <font-awesome-icon icon="plane" class="mr-50" />
                  <span class="align-middle">{{ $t('action.save_and_add') }}</span>
                </b-button>
              </div>

              <div id="saveAndQuit" v-b-tooltip.hover="organizationAircraftData.boundToTrip ? $t('aircraft.unable_edit_bound_to_trip') : ''">
                <b-button class="ml-1 mt-0" type="submit" :block="mdAndDown" :size="mdAndDown ? 'sm' : 'md'" :disabled="isSubmitting || organizationAircraftData.boundToTrip" @click="goToOrganizationAircraftsList = true">
                  <font-awesome-icon icon="arrow-right" class="mr-50" />
                  <span class="align-middle">{{ $t('action.save_and_quit') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </section>
    </validation-observer>
  </section>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { omit, pick } from 'lodash'

import { fetchAircraftRequest, fetchAircraftsRequest } from '@/request/globalApi/requests/aircraftRequests'
import {
  fetchOrganizationAircraftRequest,
  patchOrganizationAircraftRequest,
  postOrganizationAircraftRequest,
} from '@/request/globalApi/requests/organizationAircraftRequests'
import AppSelect from '@/components/AppSelect.vue'

export default defineComponent({
  name: 'OrganizationAircraftForm',

  components: { AppSelect },

  setup(_props, ctx) {
    // eslint-disable-next-line object-curly-newline
    const { $store, $router, $route, $bvModal, $i18n, _cloneDeep } = ctx.root

    // Ref template
    const aircraftForm = ref(null)

    // Selected organization from store
    const selectedOrganization = computed(() => $store.state.auth.selectedOrganization)

    // Displayed
    const isLoading = ref(true)
    const mdAndDown = computed(() => $store.getters['app/mdAndDown'])
    const showAircraftData = ref(false)
    const showInputName = ref(false)

    // Select options
    const selectedAircraft = ref(null)
    const fireFightingOptions = ref([])
    const aircraftsOptions = ref([{ name: $i18n.t('aircraft.select_other'), id: null }])
    // For v-select paginated
    const aircraftsMetaData = ref({ totalItems: 0, perPage: 12, firstPage: 1, previousPage: 1, nextPage: 1, lastPage: 1 })

    const arrayNumber = [...Array(10).keys()]
    fireFightingOptions.value = arrayNumber.map(nb => ({ label: nb + 1, value: nb + 1 }))

    // Form data
    const organizationAircraftData = ref({
      id: null,
      organizationId: null,
      boundToTrip: false,
      aircraft: null,
      name: null,
      category: null,
      manufacturer: null,
      mtow: null,
      height: null,
      length: null,
      wingspan: null,
      registration: null,
      callSign: null,
      homeBaseCode: null,
      numberOfSeats: null,
      active: true,
      aoc: null,
      maxFuelCapacity: null,
      minFlightCrew: null,
      minRunwayLength: null,
      type: null,
      model: null,
      fuelTypesIds: [],
      flightRules: [],
    })
    const initOrganizationAircraftData = _cloneDeep(organizationAircraftData.value)

    // Fetch an organization aircraft
    const fetchOrganizationAircraft = async (organizationAircraftId, isDuplication) => {
      await fetchOrganizationAircraftRequest(selectedOrganization.value.id, organizationAircraftId).then(response => {
        const data = response.data.organizationAircraft
        let omitData = ['fuelType', 'homeBase']

        // If it is a duplication, keep id to null, and also aoc and registration beacause they have a unique constraint
        if (isDuplication) omitData = ['fuelType', 'homeBase', 'id', 'aoc', 'registration']

        organizationAircraftData.value = {
          ...omit(data, omitData),
          fuelTypesIds: data.fuelTypes.map(fuelType => fuelType.id),
          homeBaseCode: data.homeBase.code,
          boundToTrip: false,
        }
      })
    }

    // Fetch a list of aircrafts
    const fetchAircrafts = (page = null, name = null) => {
      isLoading.value = true
      const queryParams = { categoryOrder: 'asc', page, name }

      fetchAircraftsRequest(queryParams).then(({ data }) => {
        aircraftsOptions.value = data.aircrafts.concat([{ name: $i18n.t('aircraft.select_other'), id: null }])
        aircraftsMetaData.value = { ...data, perPage: 12 }

        if (organizationAircraftData.value.aircraft) {
          selectedAircraft.value = organizationAircraftData.value.aircraft
        } else if (organizationAircraftData.value.id && !organizationAircraftData.value.aircraft) {
          showInputName.value = true
          showAircraftData.value = true
          selectedAircraft.value = { name: $i18n.t('aircraft.select_other'), id: null }
        }
        isLoading.value = false
      })
    }

    // Fetch the selected aicraft
    const fetchAircraft = aircraftId => {
      fetchAircraftRequest(aircraftId).then(response => {
        const { aircraft } = response.data
        organizationAircraftData.value = {
          ...organizationAircraftData.value,
          ...pick(aircraft, 'name', 'category', 'manufacturer', 'mtow', 'height', 'length', 'wingspan', 'category', 'type'),
          fuelTypesIds: aircraft.fuelTypes.map(fuelType => fuelType.id),
          active: true,
          model: aircraft.name,
        }
      })
    }

    // On created: Anonymous function auto run
    // Needed async / await for await the response of fetchOrganizationAircraft only on no add mode
    (async () => {
      const isDuplication = $route.name.includes('duplicate')
      if ($route.params.organization_aircraft_id) {
        await fetchOrganizationAircraft($route.params.organization_aircraft_id, isDuplication)
      }

      fetchAircrafts()
    })()

    const changeSelectedAircraft = aircraft => {
      if (aircraft.id) {
        showInputName.value = false
        fetchAircraft(aircraft.id)
        showAircraftData.value = true
      } else {
        showInputName.value = true
        organizationAircraftData.value = _cloneDeep(initOrganizationAircraftData)
      }
    }

    // Cancel
    const modalCancel = () => {
      $bvModal
        .msgBoxConfirm($i18n.t('aircraft.modal.cancel'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(isConfirmed => {
          if (isConfirmed) $router.push({ name: 'organization-aircrafts' })
        })
    }

    // Submit + potential redirection
    const goToOrganizationAircraftsList = ref(false)
    const isSubmitting = ref(false)

    const onSubmit = async () => {
      // Form validation
      const isFormValid = await aircraftForm.value.validate()
      if (!isFormValid) return

      isSubmitting.value = true

      const payload = _cloneDeep(omit(organizationAircraftData.value, 'fuelTypes', 'organizationId', 'boundToTrip'))

      if (selectedAircraft.value?.id) {
        payload.aircraftId = selectedAircraft.value.id
      }

      let action = patchOrganizationAircraftRequest

      if (!payload.id) {
        action = postOrganizationAircraftRequest
        delete payload.id
      }

      action(selectedOrganization.value.id, payload)
        .then(({ data }) => {
          $store.dispatch('organizationAircraft/fetchOrganizationAircrafts', true)
          // ! unauthorized this.$router.push({ name: 'organization-aircrafts' }) ... Why ?
          if (goToOrganizationAircraftsList.value) $router.go(-1)
          else if ($route.name === 'organization-aircraft-duplicate') {
            $router.push({
              name: 'organization-aircraft-edit',
              params: { organization_aircraft_id: data.id },
            })
          }
        })
        .finally(() => {
          isSubmitting.value = false
          goToOrganizationAircraftsList.value = false
        })
    }

    return {
      aircraftForm,
      isLoading,
      fireFightingOptions,
      aircraftsOptions,
      selectedAircraft,
      mdAndDown,
      isSubmitting,
      goToOrganizationAircraftsList,
      organizationAircraftData,
      showAircraftData,
      showInputName,
      changeSelectedAircraft,
      modalCancel,
      onSubmit,
      fetchAircrafts,
      aircraftsMetaData,
    }
  },
})
</script>

<style lang="scss">
#add-new-aircraft-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
